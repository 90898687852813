.input-error {
    border: 1px solid red;
    background-color: #ffe6e6;
    outline: none;
  }
  
  .input-error:focus {
    border-color: darkred;
  }

  .error-message {
    color: red;
    border:'none';
    font-size: 10px;
    margin-top: 3px;
  }